import { useState, useRef, useEffect, Suspense } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { Float } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
import { Text3D, Center, useGLTF } from "@react-three/drei";

import * as THREE from "three";
import useGame from "../../stores/useGame.js";
import Star from "./Star.jsx";
import diceModel from "../../../public/models/dice.glb"
import diceModel2 from "../../../public/models/dice_v2.glb"

const boxGeometry = new THREE.BoxGeometry(1, 1, 1);

const beachMaterial = new THREE.MeshStandardMaterial({ color: "blue" });
const obstacleMaterial = new THREE.MeshStandardMaterial({ color: "tomato" });
const testMaterial = new THREE.MeshStandardMaterial({ color: "red" });
export const blockDimensions = {
  width: 4.2,
  height: 0.3,
  length: 4,
};

/**
 * BlockEmpty
 */
export function BlockEmpty({ position = [0, 0, 0] }) {
  return (
    <group position={position}>
      <mesh
        geometry={boxGeometry}
        material={beachMaterial}
        position={[0, -0.2, 0]}
        scale={[
          blockDimensions.width,
          blockDimensions.height,
          blockDimensions.length,
        ]}
        receiveShadow
      />
    </group>
  );
}

/**
 * BlockSpinner
 */
export function BlockSpinner({
  position = [0, 0, 0],
  difficulty,
  margin = 0.5,
}) {
  const chip = useGLTF("./models/poker_chip.glb");
  
  const obstacle = useRef();
  const [speed] = useState(
    () => (Math.random() + difficulty + 0.5) * (Math.random() < 0.5 ? -1 : 1)
  );

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    const rotation = new THREE.Quaternion();
    rotation.setFromEuler(new THREE.Euler(0, time * speed, 0));
    obstacle.current.setNextKinematicRotation(rotation);
  });

  return (
    <Suspense fallback={null}>
      {chip !== null &&(
      <group position={position}>
        <mesh
          geometry={boxGeometry}
          material={beachMaterial}
          position={[0, -0.2, 0]}
          scale={[
            blockDimensions.width,
            blockDimensions.height,
            blockDimensions.length,
          ]}
          receiveShadow
        />
        <RigidBody
          ref={obstacle}
          type="kinematicPosition"
          position={[0, -0.1, 0]}
          restitution={0.2}
          friction={0}
        >
          <mesh scale={[1, 1, 1]} position={[0, 1.2, 0]} rotation={[1.5, 0, 0]}>
            <primitive object={chip.scene} />
          </mesh>
        </RigidBody>
      </group>
      )}
    </Suspense>
  );
}

/**
 * BlockDoubleSpinner
 */
export function BlockDoubleSpinner({ position = [0, 0, 0], difficulty }) {
  const obstacle1 = useRef();
  const obstacle2 = useRef();

  const [direction] = useState(() => (Math.random() < 0.5 ? -1 : 1));
  const [speed] = useState(() => difficulty * 2 * direction);

  const horse = useGLTF("./models/horse.glb");
  const horse2 = useGLTF("./models/horse2.glb");

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    const rotation1 = new THREE.Quaternion();
    rotation1.setFromEuler(new THREE.Euler(0, time * speed, 0));
    obstacle1.current.setNextKinematicRotation(rotation1);

    const rotation2 = new THREE.Quaternion();
    rotation2.setFromEuler(new THREE.Euler(0, time * -speed, 0));
    obstacle2.current.setNextKinematicRotation(rotation2);
  });

  return (
    <group position={position}>
      <mesh
        geometry={boxGeometry}
        material={beachMaterial}
        position={[0, -0.2, 0]}
        scale={[
          blockDimensions.width,
          blockDimensions.height,
          blockDimensions.length,
        ]}
        receiveShadow
      />
      <RigidBody
        ref={obstacle1}
        type="kinematicPosition"
        position={[blockDimensions.width / 4, -0.1, 0]}
        restitution={0.2}
        friction={0}
      >
        <mesh position={[0, -0.01, 0]} scale={[0.05, 0.05, 0.05]}>
          <primitive object={horse.scene} />
        </mesh>
      </RigidBody>
      <RigidBody
        ref={obstacle2}
        type="kinematicPosition"
        position={[-blockDimensions.width / 4, -0.1, 0]}
        restitution={0.2}
        friction={0}
      >
        <mesh position={[0, -0.01, 0]} scale={[0.05, 0.05, 0.05]}>
          <primitive object={horse2.scene} />
        </mesh>
      </RigidBody>
    </group>
  );
}

/**
 * BlockLimbo
 */
export function BlockLimbo({ position = [0, 0, 0], difficulty }) {
  const dice = useGLTF(diceModel);
  const dice2 = useGLTF(diceModel2);
  const obstacle = useRef();
  const [timeOffset] = useState(() => Math.random() * Math.PI * 2);
  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    const y = Math.sin(1.5 * difficulty * time + timeOffset) + 1.3;
    obstacle.current.setNextKinematicTranslation({
      x: position[0],
      y: position[1] + y,
      z: position[2],
    });
  });

  return (
    <group position={position}>
      <mesh
        geometry={boxGeometry}
        material={beachMaterial}
        position={[0, -0.2, 0]}
        scale={[
          blockDimensions.width,
          blockDimensions.height,
          blockDimensions.length,
        ]}
        receiveShadow
      />
      <RigidBody
        ref={obstacle}
        type="kinematicPosition"
        restitution={0.2}
        friction={0}
      >
        <group>
          <mesh position={[1, 0.5, 0]}>
            <primitive object={dice.scene} />
          </mesh>
          <mesh position={[-1, 0.5, 0]}>
            <primitive object={dice2.scene} />
          </mesh>
        </group>
      </RigidBody>
    </group>
  );
}

/**
 * BlockDoubleLimbo
 */
export function BlockDoubleLimbo({ position = [0, 0, 0], difficulty }) {
  const obstacle1 = useRef();
  const obstacle2 = useRef();
  const [timeOffset] = useState(() => Math.random() * Math.PI * 2);

  const football = useGLTF("./models/football_goal_post.glb");
  const football2 = useGLTF("./models/football_goal_post2.glb");

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    const y1 = 0.3 * Math.sin(difficulty * 1.5 * time + timeOffset) + 1.3;
    obstacle1.current.setNextKinematicTranslation({
      x: position[0],
      y: position[1] + y1 + 0.2,
      z: position[2],
    });

    const y2 = -0.3 * Math.sin(1.5 * difficulty * time + timeOffset) + 1.3;
    obstacle2.current.setNextKinematicTranslation({
      x: position[0],
      y: position[1] + y2 - 0.8,
      z: position[2],
    });
  });

  return (
    <group position={position}>
      <mesh
        geometry={boxGeometry}
        material={beachMaterial}
        position={[0, -0.2, 0]}
        scale={[
          blockDimensions.width,
          blockDimensions.height,
          blockDimensions.length,
        ]}
        receiveShadow
      />
      <RigidBody
        ref={obstacle1}
        type="kinematicPosition"
        restitution={0.2}
        friction={0}
      >
        <mesh>
          <primitive position={[0, -0.9, 0]} object={football.scene} />
        </mesh>
      </RigidBody>
      <RigidBody
        ref={obstacle2}
        type="kinematicPosition"
        restitution={0.2}
        friction={0}
      ></RigidBody>
    </group>
  );
}

/**
 * BlockPlatformLimbo
 */
export function BlockPlatformLimbo({ position = [0, 0, 0], difficulty }) {
  const obstacle = useRef();
  const [timeOffset] = useState(() => Math.random() * Math.PI * 2);

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    const y = Math.sin(1.5 * difficulty * time + timeOffset) + 1.3;
    obstacle.current.setNextKinematicTranslation({
      x: position[0],
      y: position[1] + y,
      z: position[2],
    });
  });

  return (
    <group position={position}>
      <mesh
        geometry={boxGeometry}
        material={beachMaterial}
        position={[0, -0.2, 0]}
        scale={[
          blockDimensions.width,
          blockDimensions.height,
          blockDimensions.length,
        ]}
        receiveShadow
      />
      <RigidBody
        ref={obstacle}
        type="kinematicPosition"
        restitution={0.2}
        friction={0}
      >
        <mesh
          geometry={boxGeometry}
          material={obstacleMaterial}
          scale={[4, 0.3, 3]}
          castShadow
          receiveShadow
        />
      </RigidBody>
    </group>
  );
}

/**
 * BlockRamp
 */
export function BlockRamp({ position = [0, 0, 0], difficulty }) {
  return (
    <group position={position}>
      <mesh
        geometry={boxGeometry}
        material={beachMaterial}
        position={[0, -0.2, 0]}
        scale={[
          blockDimensions.width,
          blockDimensions.height,
          blockDimensions.length,
        ]}
        receiveShadow
      />
      <RigidBody type="kinematicPosition" restitution={0.2} friction={0}>
        <mesh
          geometry={boxGeometry}
          material={obstacleMaterial}
          position={[0, 0.4, 0]}
          scale={[4, 0.3, 1.5]}
          rotation={[0.75, 0, 0]}
          castShadow
          receiveShadow
        />
      </RigidBody>
    </group>
  );
}

/**
 * BlockSlidingWall
 */
export function BlockSlidingWall({ position = [0, 0, 0], difficulty }) {
  const betting_machine = useGLTF("./models/betting_machine.glb");
  const obstacle = useRef();
  const [timeOffset] = useState(() => Math.random() * Math.PI * 2);

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    const x = Math.sin(difficulty * 1.5 * time + timeOffset) * 1.25;
    obstacle.current.setNextKinematicTranslation({
      x: position[0] + x,
      y: position[1] + 0.75,
      z: position[2],
    });
  });

  return (
    <Suspense fallback={null}>
      {betting_machine !== null && (
    <group position={position}>
      <mesh
        geometry={boxGeometry}
        material={beachMaterial}
        position={[0, -0.2, 0]}
        scale={[
          blockDimensions.width,
          blockDimensions.height,
          blockDimensions.length,
        ]}
        receiveShadow
      />
      <RigidBody
        ref={obstacle}
        type="kinematicPosition"
        restitution={0.2}
        friction={0}
      >
        <mesh
          scale={[2, 2, 2]}
          position={[0, -0.04, 0]}
          castShadow={true}
          receiveShadow={true}
        >
          <primitive object={betting_machine.scene} />
        </mesh>
      </RigidBody>
    </group>
    )}
    </Suspense>
  );
}

/**
 * BlockDoubleSlidingWall
 */
export function BlockDoubleSlidingWall({ position = [0, 0, 0], difficulty }) {
  const card = useGLTF("./models/pack_of_cards.glb");
  const card2 = useGLTF("./models/pack_of_cards2.glb");
  const wall1 = useRef();
  const wall2 = useRef();

  const [timeOffset] = useState(() => Math.random() * Math.PI * 2);

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    const x1 = Math.sin(difficulty * 2 * time + timeOffset) * 0.5 + 1;
    wall1.current.setNextKinematicTranslation({
      x: position[0] + x1,
      y: position[1] + 0.75,
      z: position[2],
    });

    const x2 = -Math.sin(difficulty * 2 * time + timeOffset) * 0.5 - 1;
    wall2.current.setNextKinematicTranslation({
      x: position[0] + x2,
      y: position[1] + 0.75,
      z: position[2],
    });
  });

  return (
    <Suspense fallback={null}>
    <group position={position}>
      <mesh
        geometry={boxGeometry}
        material={beachMaterial}
        position={[0, -0.2, 0]}
        scale={[
          blockDimensions.width,
          blockDimensions.height,
          blockDimensions.length,
        ]}
        receiveShadow
      />
      <RigidBody
        ref={wall1}
        type="kinematicPosition"
        restitution={0.2}
        friction={0}
      >
        <mesh
          scale={[0.8, 0.8, 0.8]}
          position={[0.3, 0.2, 0]}
          castShadow={true}
          receiveShadow={true}
        >
          <primitive object={card2.scene} />
        </mesh>
      </RigidBody>
      <RigidBody
        ref={wall2}
        type="kinematicPosition"
        restitution={0.2}
        friction={0}
      >
        <mesh
          scale={[0.8, 0.8, 0.8]}
          position={[-0.3, 0.2, 0]}
          castShadow={true}
          receiveShadow={true}
        >
          <primitive object={card.scene} />
        </mesh>
      </RigidBody>
    </group>
    </Suspense>
  );
}

/**
 * BlockEnd
 */
export function BlockEnd({ position = [0, 0, 0] }) {
  const { end } = useGame();

  function onHit() {
    end();
  }

  return (
    <group position={position}>
      <mesh
        geometry={boxGeometry}
        material={beachMaterial}
        position={[0, -0.2, 0]}
        scale={[
          blockDimensions.width,
          blockDimensions.height,
          blockDimensions.length,
        ]}
        receiveShadow
      />
      <Float
        speed={25}
        rotationIntensity={0.25}
        floatIntensity={0.25}
        floatingRange={[-0.01, 0.01]}
      >
        <RigidBody
          type="fixed"
          colliders="trimesh"
          position={[0, 1.05, 0]}
          rotation={[0, Math.PI / 2, 0]}
          restitution={0.2}
          friction={0}
          onCollisionEnter={onHit}
        >
          <Star scale={0.012} />
        </RigidBody>
      </Float>
    </group>
  );
}
