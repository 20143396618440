import { Html } from "@react-three/drei";
import { Physics } from "@react-three/rapier";
import { Perf } from "r3f-perf";
import Lights from "./Lights.jsx";
import { RandomLevel, TourLevel } from "./level/Level.jsx";

import Ball from "./Ball.jsx";
import useGame from "./stores/useGame.js";
import useAudio from "./stores/useAudio.js";
import { SoundManager } from "./utils/SoundManager.jsx";
import { Suspense, useEffect, useState } from "react";
import Loading from "./interface/Loading.jsx";

export default function Experience() {
  const mode = useGame((state) => state.mode);
  const performance = useGame((state) => state.performance);
  const showPerformance = useGame((state) => state.showPerformance);
  const restart = useGame((state) => state.restart);
  const difficulty = useGame((state) => state.difficulty);
  const blocksCount = useGame((state) => state.blocksCount);
  const blocksSeed = useGame((state) => state.blocksSeed);
  const toggleAudio = useAudio((state) => state.toggleAudio);

  useEffect(() => {
    setTimeout(restart(), 5000);

    const handleKeyDown = (e) => {
      // Restart game
      if (e.code === "KeyR") {
        restart();
      }

      // Toggle sound
      else if (e.code === "KeyM") {
        toggleAudio();
      }

      // // Toggle performance
      // else if (e.code === "KeyP") {
      //   showPerformance();
      // }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [restart, toggleAudio, showPerformance]);



  return (
    <>
      <Suspense fallback="Loading...">
        <color args={["#00bfff"]} attach="background" />
        {performance && <Perf position="bottom-left" />}
        <Physics debug={false}>
          <Lights />

          <RandomLevel
            count={blocksCount}
            seed={blocksSeed}
            difficulty={difficulty}
          />

          <Ball />
          <Html className=" w-[300px] left-[-150px] md:w-[500px] md:left-[-250px] bg-orange-400 mx-auto">
            <div className="my-5 flex flex-col text-center gap-3">
              <h2 className=" w-4/5 mx-auto text-2xl font-semibold">玩法</h2>
              <p className=" text-base">使用鍵盤方向鍵或 "W, A, S, D"移動皮球，避開賭博物品</p>
              <p className=" text-xl font-bold text-red-900">請同時按R鍵+鍵盤方向鍵開始遊戲</p>
      
            </div>
          </Html>
          <SoundManager />
        </Physics>
      </Suspense>
    </>
  );
}
