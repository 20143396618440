import { useState } from "react";
import useGame from "../stores/useGame.js";
import logo from "../assets/worsd.png";
import hkeipaaLogo from "../assets/hkeipaa_logo.png";
import pw_logo from "../assets/pw_logo.png";

export default function MainMenu() {
  const {
    setIsInGame,
  } = useGame();

  const [isSettings, setIsSettings] = useState(false);

  document.addEventListener("keydown", (e) => {
    if (e.code === "Enter") {
      setIsInGame(true);
    }
  });

  return (
    <div className=" bg-sky-700 bg-opacity-70 w-full h-full">
      <div className=" flex flex-col gap-3 w-full items-center">
        <div className=" flex flex-row gap-5 mx-auto px-10">
          <div className=" w-1/5 h-auto mx-auto flex flex-row justify-center items-center">
            <p className="w-[250px]">主辦：</p>
            <img className="w-[150px]" src={hkeipaaLogo} />
          </div>
          <div className=" w-3/5 flex flex-col justify-center items-center">
            <img className="logo-ball" src={logo} />
          </div>
          <div className=" w-1/5 h-auto mx-auto flex flex-row justify-center items-center">
            <p>贊助：</p>
            <img className="w-[100px]" src={pw_logo} />
          </div>
        </div>
        <h1 className=" text-4xl font-bold">勇闖不賭城</h1>
        <h1 className=" text-3xl font-bold">抗毒彩虹波</h1>
        <h3 className="text-2xl">遊戲玩法</h3>
        <p>先看防賭短片，之後按開始遊戲</p>
        <div>
          <iframe
            src="https://player.vimeo.com/video/900114548?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            allow="autoplay; fullscreen; picture-in-picture"
            className=" top-0 left-0 w-full h-auto md:w-[720px] md:h-[400px]"
            title="網上賭博的種類及禍害"
          ></iframe>
        </div>
        <p>**必須使用電腦及鍵盤**</p>
        <div
          className=" bg-white text-blue-400 rounded-md font-bold text-2xl py-5 px-12"
          onClick={() => setIsInGame(true)}
        >
          開始遊戲
        </div>
      </div>
      <div className="main-menu-about-section">
        <div className="main-menu-about">
          <a href="https://hkeipaa.org">© 2023 HKEIPAA</a>
        </div>
      </div>
    </div>
  );
}
